import React, { useState, useEffect } from 'react'
import { PageProps } from 'gatsby'
import { useContext } from '~/hooks'
import { Head, Button, Footer, Lazyload } from '~/components'
import * as st from '~/assets/styl/NotFound.module.styl'
import Banner from '~/assets/img/Pagina-nao-encontrada.jpg'

const NotFound = ({ location }: PageProps) => {
  const { setLoading } = useContext()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setLoading(false)
    setReady(true)
  }, [])

  return ready ? (
    <>
      <Head
        location={location}
        title={'Página não encontrada - ' + process.env.GATSBY_SITE_NAME}
      />
      <header className={st.core}>
        <div className={st.container}>
          <div className={st.box}>
            <Lazyload src={Banner} className={st.img} />
            <div className={st.text}>
              <h1>Página não encontrada</h1>
              <p>
                Erro 404. A página que você tentou acessar não existe ou está
                fora do ar.
              </p>
              <Button href="/" className={st.btn}>
                Ir para a home
              </Button>
              <Button href="/empreendimentos/" className={st.btn}>
                Conheça os empreendimentos
              </Button>
            </div>
          </div>
        </div>
      </header>
      <Footer />
    </>
  ) : (
    <Head location={location} />
  )
}

export default NotFound
